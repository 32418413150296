body {
  margin: 0;
  padding: 0;
  background-color: #FFFFFF !important;
  /* Fondo blanco para todo el cuerpo */
}

canvas {
  margin-top: 0;
  padding-top: 0;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF !important;
  /* Ya está configurado */
  font-family: 'Roboto', sans-serif;
}

.githubIcon {
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
  /* Mantener el color negro para el ícono */
}

.loading {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: #82E0AA;
  /* Verde pastel vibrante */
}


.switch-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.switch {
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: background-color 0.4s ease;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Estado del slider activado */
input:checked+.slider {
  background-color: #85C1E9;
  /* Azul pastel vibrante */
}

input:checked+.slider:before {
  transform: translateX(26px);
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Efecto al hacer hover sobre el slider */
.slider:hover:before {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Efecto al hacer focus sobre el slider */
input:focus+.slider {
  box-shadow: 0 0 1px #85C1E9;
  /* Resplandor azul pastel */
}

/* Efecto al hacer focus sobre el botón del slider */
input:focus+.slider:before {
  background-color: #f1f1f1;
}

.controls {
  position: absolute;
  top: 20px;
  /* Ajusta esto para alinear el borde superior */
  right: 20px;
  /* Asegura que esté alineado a la derecha */
  width: 200px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid #F8C471;
  /* Borde amarillo pastel */
  transition: all 0.3s ease;
  /* Para animar el cambio de visibilidad */
}

.controls.hidden {
  visibility: hidden;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.controls label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.controls input[type="range"] {
  width: 100%;
  margin-bottom: 15px;
  -webkit-appearance: none;
  appearance: none;
  background: #F9E79F;
  /* Amarillo pastel para el fondo de la barra */
  height: 6px;
  border-radius: 5px;
}

.controls input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #F4D03F;
  /* Amarillo más intenso para el botón deslizante */
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.buttons-container {
  display: flex;
  justify-content: center;
  /* Centra los botones en el contenedor */
  align-items: center;
  /* Alinea verticalmente los botones */
  gap: 10px;
  /* Espacio entre los botones */
  margin-top: 20px;
  /* Ajusta este margen para la alineación deseada */
  width: 100%;
  /* Hace que el contenedor ocupe todo el ancho */
}

.control-button {
  background-color: #A569BD;
  /* Morado pastel */
  color: #FFFFFF;
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.control-button.small-button {
  padding: 6px 12px;
  font-size: 13px;
}

.control-button:hover {
  background-color: #8E44AD;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);
}

.control-button:active {
  background-color: #6C3483;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.control-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  box-shadow: none;
}

.control-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(165, 105, 189, 0.5);
  /* Contorno morado pastel */
}

.file-label {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.file-label input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.background-container {
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin: 0;
  padding: 0;
}

.toggle-container {
  position: absolute;
  top: 20px;
  /* Alineado con el control de la derecha */
  left: 20px;
  /* Alineado a la izquierda */
  display: flex;
  align-items: center;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid #F8C471;
  /* Borde amarillo pastel */
  transition: all 0.3s ease;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #FFFFFF;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.container,
.wrapper,
.controls,
.buttons-container {
  background-color: #FFFFFF;
  /* Asegura fondo blanco para todos estos contenedores */
}