/* Estilo para oscurecer el fondo */
.loading-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Estilo para el contenedor del spinner y el texto */
  .spinner-container {
    text-align: center;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }
  
  /* Estilo para el spinner */
  .spinner {
    border: 6px solid rgba(255, 255, 255, 0.3);
    border-top: 6px solid #fcb8d2; /* Ajusta este color según tus preferencias */
    border-radius: 50%;
    width: 60px; /* Tamaño más grande */
    height: 60px;
    margin: 0 auto;
    animation: spin 1s linear infinite;
  }
  
  /* Estilo para el texto de carga */
  .loading-text {
    color: white;
    margin-top: 20px;
    font-size: 24px; /* Tamaño de fuente más grande */
    font-weight: 500;
    font-family: 'Arial', sans-serif;
    text-shadow: 2px 2px 2px black; /* Sombra de texto para el borde */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  